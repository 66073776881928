import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import { StaticImage } from "gatsby-plugin-image"

const Page = (Maintain) => (

  <Layout>
    <div className="contentWrapper">
     
        <StaticImage 
        className="SplashImage" 
        src="../images/maintain.jpg" 
        alt="Maintain Consumer Application"/>
   
 
          <div className="casestudy">
            <h1>Maintain.</h1>
            <dl>
              <dt>Role:</dt>
              <dd>Product Designer</dd>
       
              <dt>Company:</dt>
              <dd>Driven Analytics</dd>

              <dt>Scale:</dt>
              <dd>Mobile Interface and Marketing Site. </dd>
           
              <dt>Challenge:</dt>
              <dd>Maintain was a vehicle maintenance tracking application that offered many features to users but lacked product market fit. Maintain heavily relied on dealership employees to promote the product, and users found the UI hard to use. Comments in the app store showed an overall untrusting attitude toward the application. </dd>
      
              <dt>Process:</dt>
              <dd>I met with Stakeholders to understand the technology behind Maintain and where there had been friction in finding product market fit. We discussed opportunities and the feasibility of leveraging the current technology. We created a questionnaire and interviewed 30+ vehicle owners to understand the pain points of their consumer experience. With the information gathered, we chose a new direction for the technology. </dd>
             
              <dt>Hypothesis:</dt>
              <dd>Positioning Maintain as a scheduling assistant for users and a record tracking tool for service providers, we could foster trustworthy relationships between consumers and service providers to improve customer retention.</dd>
          
              <dt>Results:</dt>
              <dd>Maintain went into a pilot program as a fully mobile (they come to you) oil change and maintenance service company. The company started earning for the first time since its inception.  </dd>
            </dl>
          </div>

          <Footer/>
    </div>
   
 </Layout> 

)

export const Head = (Maintain) => <Seo title="Maintain" />

export default Page

